/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
import Pui9HttpRequests from '@Pui9Requests';

import constants from '@/utils/constants';
import { lastData } from '@/api/databases_API';
import { query_LastDataByParameter, query_HistoricDataByParameter, query_FirstDataByParameter } from '@/utils/queries';
import { waterportsquery_LastDataByWaterPollutant, waterportsquery_FirstDataByWaterPollutant } from '@/utils/waterports_queries';
import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';

import moment from 'moment-timezone';

export async function search(body) {
	if (!Object.hasOwn(body, 'rows')) body.rows = 100;
	const controller = '/puisearch';
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);

	return data;
}

export function filterDependOnRole(session) {
	let filterByOrganization = null;

	if (!isSuperAdmin(session.profile)) {
		filterByOrganization = {
			groups: [],
			groupOp: 'and',
			rules: [{ field: 'pmorganizationid', op: 'eq', data: session.organizationid }]
		};
	}
	return filterByOrganization;
}

export function filterDependOnRoleAndDataSourceType(datasourcetypeid, session) {
	let filterByOrganization = null;

	if (!isSuperAdmin(session.profile)) {
		filterByOrganization = {
			groups: [],
			groupOp: 'and',
			rules: [
				{ field: 'pmorganizationid', op: 'eq', data: session.organizationid },
				{ field: 'pmdatasourcetypeid', op: 'eq', data: datasourcetypeid },
				{ field: 'disabled', op: 'eq', data: 0 }
			]
		};
	} else {
		filterByOrganization = {
			groups: [],
			groupOp: 'and',
			rules: [
				{ field: 'pmdatasourcetypeid', op: 'eq', data: datasourcetypeid },
				{ field: 'disabled', op: 'eq', data: 0 }
			]
		};
	}

	return filterByOrganization;
}

export function filterDependOnRoleAndLanguage(session) {
	let filterByOrganization = null;

	if (!isSuperAdmin(session.profile)) {
		filterByOrganization = {
			groups: [],
			groupOp: 'and',
			rules: [
				{ field: 'pmorganizationid', op: 'eq', data: session.organizationid },
				{ field: 'lang', op: 'eq', data: session.language }
			]
		};
	}
	return filterByOrganization;
}

export function dockerObjectForFilter(session) {
	return {
		profile: session.profile,
		acronym: session.acronym
	};
}

export function getOrganization(session) {
	return session.acronym;
}

export function getOrganizationId(session) {
	return session.organizationid;
}

export function isSuperAdmin(profile) {
	return profile === constants.SUPER_ADMIN_ROLE;
}

export async function getAreas(lang, session) {
	var rulesAreas = isSuperAdmin(session.profile)
		? [
				{ field: 'lang', op: 'eq', data: lang },
				{ field: 'disabled', op: 'eq', data: 0 }
			]
		: [
				{ field: 'lang', op: 'eq', data: lang },
				{ field: 'pm_organization_id', op: 'eq', data: session.organizationid },
				{ field: 'disabled', op: 'eq', data: 0 }
			];

	const body = {
		model: 'pmarea',
		filter: {
			groups: [],
			groupOp: 'and',
			rules: rulesAreas
		}
	};
	const { data } = await search(body);

	return data;
}

export async function getDatasourcetype(lang, session) {
	var rulesDtsType = isSuperAdmin(session.profile)
		? [
				{ field: 'lang', op: 'eq', data: lang },
				{ field: 'disabled', op: 'eq', data: 0 }
			]
		: [
				{ field: 'lang', op: 'eq', data: lang },
				{ field: 'pm_organization_id', op: 'eq', data: session.organizationid },
				{ field: 'disabled', op: 'eq', data: 0 }
			];
	const filterDatasourcetype = {
		groups: [],
		groupOp: 'and',
		rules: rulesDtsType
	};

	const body = {
		model: 'pmdatasourcetype',
		filter: filterDatasourcetype
	};

	const { data } = await search(body);
	return data;
	/* store.commit('setDatasourcetype', data.data); */
}
// NOTE: UNUSED
export function storeIconsType(iconsType, mutation, store) {
	store.commit(
		mutation,
		store.getters.getIcons.filter((icon) => icon.iconstypeacronym == iconsType)
	);
}

export function getIconsType(iconsType, icons) {
	return icons.filter((icon) => icon.iconstypeacronym == iconsType);
}

export function getIconsTypeIcon(iconsType, iconAcronym, icons) {
	return getIconsType(iconsType, icons).filter((icon) => icon.acronym == iconAcronym)[0];
}

export function getStringFeatureImage(urlBase, route) {
	return urlBase + route;
}

export function getImagePath(urlBase, route) {
	return new URL(urlBase + route); //, import.meta.url);
}

export function getDateByFormatandTimeZone(timestamp, timezone, constantFormat, onlyDate = false) {
	let localTime;

	console.log('Timestamp:', timestamp);
    console.log('Timezone:', timezone);
    console.log('Constant Format:', constantFormat);

    // Verifico si el timestamp es un número (milisegundos)
    if (typeof timestamp === 'number') {
        // Aplico directamente la zonoa horaria especificada sobre el timestamp
        localTime = moment.tz(timestamp, timezone);
    } else {
        // Verificor si el timestamp es una cadena que termina con 'Z' (indica UTC)
        if (timestamp.endsWith('Z')) {
            localTime = moment.utc(timestamp).tz(timezone);
        } else {
            // Asumo que el timestamp está en la zona horaria especificada
            localTime = moment.tz(timestamp, timezone);
        }
    }

    // Los siguientes console.log coincidiran en el 100% de los casos
	console.log('Local time in current timezone:', localTime.format());
    console.log('Local time in current timezone with format:', localTime.format(constantFormat));

	// Aplicar el formato especificado
    let dateByFormat = localTime.format(constantFormat);

    // Dejo el siguiente comentario como prueba para ver las siguientes conversiones
	/*
	// Convertir el tiempo local a UTC
    const utcTime = localTime.clone().utc();
    console.log('UTC time:', utcTime.format());

    // Convertir a la zona horaria especificada
    const targetTime = utcTime.clone().tz(timezone);
    console.log('Local time in timezone:', timezone, targetTime.format());
	*/

	if (onlyDate) {
        dateByFormat = dateByFormat.split(' ')[0];
    }
	
	console.log('Formatted date:', dateByFormat);
	return dateByFormat;
}

export function getDateByFormat(timestamp, constantFormat, onlyDate = false) {
	var date = new Date(timestamp);
	var dateByFormat = null;
	switch (constantFormat) {
		case constants.DATE_FORMAT_GMT:
			//GMT
			if (onlyDate) dateByFormat = date.toUTCString().split(' ').splice(0, 4).join(' ');
			else dateByFormat = date.toUTCString();
			break;
		case constants.DATE_FORMAT_GENERIC_FORMAT:
			//22:41 Sun Jan 30 2005
			if (onlyDate) dateByFormat = date.toDateString();
			else
				dateByFormat =
					date.getHours().toString().padStart(2, 0) + ':' + date.getMinutes().toString().padStart(2, 0) + ', ' + date.toDateString();
			break;
		case constants.DATE_FORMAT_SPANISH_FORMAT:
			//30/01/2005 22:45:51
			if (onlyDate)
				dateByFormat =
					date.getDate().toString().padStart(2, 0) + '/' + (date.getMonth() + 1).toString().padStart(2, 0) + '/' + date.getFullYear();
			else
				dateByFormat =
					date.getDate().toString().padStart(2, 0) +
					'/' +
					(date.getMonth() + 1).toString().padStart(2, 0) +
					'/' +
					date.getFullYear() +
					' ' +
					date.getHours().toString().padStart(2, 0) +
					':' +
					date.getMinutes().toString().padStart(2, 0) +
					':' +
					date.getSeconds().toString().padStart(2, 0);
			break;
		case constants.DATE_FORMAT_EUROPEAN_FORMAT:
			//2005/01/30 22:41:51
			if (onlyDate)
				dateByFormat =
					date.getFullYear() + '/' + (date.getMonth() + 1).toString().padStart(2, 0) + '/' + date.getDate().toString().padStart(2, 0);
			else
				dateByFormat =
					date.getFullYear() +
					'/' +
					(date.getMonth() + 1).toString().padStart(2, 0) +
					'/' +
					date.getDate().toString().padStart(2, 0) +
					' ' +
					date.getHours().toString().padStart(2, 0) +
					':' +
					date.getMinutes().toString().padStart(2, 0) +
					':' +
					date.getSeconds().toString().padStart(2, 0);
			break;
		default:
			if (onlyDate)
				dateByFormat = dateByFormat =
					date.getFullYear() + '/' + (date.getMonth() + 1).toString().padStart(2, 0) + '/' + date.getDate().toString().padStart(2, 0);
			else
				dateByFormat = dateByFormat =
					date.getFullYear() +
					'/' +
					(date.getMonth() + 1).toString().padStart(2, 0) +
					'/' +
					date.getDate().toString().padStart(2, 0) +
					' ' +
					date.getHours().toString().padStart(2, 0) +
					':' +
					date.getMinutes().toString().padStart(2, 0) +
					':' +
					date.getSeconds().toString().padStart(2, 0);
			break;
	}
	return dateByFormat;
}

export async function obtainPopupElasticLastData(stationCode, indexName, query, store) {
	const promise = lastData(indexName, query);
	promise.then((elasticData) => {
		let data = false;
		if (typeof elasticData.message != 'string') {
			let messageData = elasticData.message[0]._source;
			if (Object.hasOwn(messageData, 'validations') && messageData.validations.length > 0) {
				let parameterValidations = processDocumentValidations(
					messageData.validations,
					store.getters.getUserProperties,
					store.getters.getErrorCodes
				);
				if (parameterValidations.length > 0) messageData.validations = parameterValidations;
			}
			elasticData.message[0]._source = messageData;
			data = elasticData;
		}
		store.commit('setPopupElasticData', data);
	});
}

export async function obtainPanelRealTimeData(stationCode, datasourcetypeId, indexName, store) {
	var parametersElasticData = [];
	var parametersElasticValidations = [];
	var parametersLastDateData = [];
	var parametersAcronyms = [];
	var promisesElastic = [];
	var datasourceParameters = store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == datasourcetypeId);

	datasourceParameters.forEach((parameter) => {
		promisesElastic.push(lastData(indexName, query_LastDataByParameter(stationCode, parameter.elasticacronym)));
		parametersAcronyms.push(parameter.elasticacronym);
	});

	Promise.all(promisesElastic).then((elasticData) => {
		for (let i = 0; i < elasticData.length; i++) {
			if (typeof elasticData[i].message[0] !== 'undefined') {
				let messageData = elasticData[i].message[0]._source;
				if (Object.hasOwn(messageData, 'validations') && messageData.validations.length > 0) {
					let parameterValidations = processDocumentValidations(
						messageData.validations,
						store.getters.getUserProperties,
						store.getters.getErrorCodes,
						parametersAcronyms[i]
					);
					if (parameterValidations.length > 0) messageData.validations = parameterValidations;
				}
				parametersElasticData[parametersAcronyms[i]] = messageData;

				parametersLastDateData[parametersAcronyms[i]] = new Date(messageData['dateObserved_timestamp']).toISOString();
			}
		}

		//parametersElasticData[parameter.elasticacronym] = elasticData;
		store.commit('setRealTimeElasticData', parametersElasticData);
		store.commit('setHistoricLastDates', parametersLastDateData);
	});
}

export function processDocumentValidations(validations, session, errorCodes, parameterAcronym = null) {
	let parameterValidations = null;
	if (parameterAcronym) {
		// Feature Map Panel
		parameterValidations = [];
		validations.forEach((validation) => {
			let validationObj = null;
			if (validation.detail.parameter == parameterAcronym) {
				let errorMessage = validation.message;
				if (validation.lang != session.language && validation.lang != constants.NO_LANG) {
					let errorCode = getErrorCode(validation.acronym, errorCodes);
					if (errorCode) errorMessage = errorCode.message;
				}

				if (Array.isArray(validation.detail)) {
					validation.detail.forEach((detailObj) => {
						parameterValidations.push({
							acronym: validation.acronym,
							message: errorMessage,
							parameter: detailObj.parameter,
							value: detailObj['message_value'],
							info: detailObj['message_info']
						});
					});
				} else {
					parameterValidations.push({
						acronym: validation.acronym,
						message: errorMessage,
						parameter: validation.detail.parameter,
						value: validation.detail['message_value'],
						info: validation.detail['message_info']
					});
				}
			}
		});
	} else {
		// Feature Popup
		parameterValidations = [];
		validations.forEach((validation) => {
			let errorMessage = validation.message;
			if (validation.lang != session.language && validation.lang != constants.NO_LANG) {
				let errorCode = getErrorCode(validation.acronym, errorCodes);
				if (errorCode) errorMessage = errorCode.message;
			}
			if (Array.isArray(validation.detail)) {
				validation.detail.forEach((detailObj) => {
					parameterValidations.push({
						acronym: validation.acronym,
						message: errorMessage,
						parameter: detailObj.parameter,
						value: detailObj['message_value'],
						info: detailObj['message_info']
					});
				});
			} else {
				parameterValidations.push({
					acronym: validation.acronym,
					message: errorMessage,
					parameter: validation.detail.parameter,
					value: validation.detail['message_value'],
					info: validation.detail['message_info']
				});
			}
		});
	}
	return parameterValidations;
}

export function getErrorCode(acronym, errorCodes) {
	if (errorCodes.length > 0) return errorCodes.find((code) => code.acronym == acronym);
}

export async function obtainPanelHistoricFirstData(stationCode, datasourcetypeId, indexName, store) {
	var parametersFirstDateData = [];
	var parametersAcronyms = [];
	var promisesElastic = [];
	var datasourceParameters = store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == datasourcetypeId);

	datasourceParameters.forEach((parameter) => {
		promisesElastic.push(lastData(indexName, query_FirstDataByParameter(stationCode, parameter.elasticacronym)));
		parametersAcronyms.push(parameter.elasticacronym);
	});

	Promise.all(promisesElastic).then((elasticData) => {
		for (let i = 0; i < elasticData.length; i++) {
			if (typeof elasticData[i].message[0] !== 'undefined') {
				let messageData = elasticData[i].message[0]._source;
				parametersFirstDateData[parametersAcronyms[i]] = new Date(messageData['dateObserved_timestamp']).toISOString();
			}
		}
		//parametersElasticData[parameter.elasticacronym] = elasticData;
		store.commit('setHistoricFirstDates', parametersFirstDateData);
	});
}
// NOTE: UNUSED
export async function obtainPanelHistoricData(stationCode, indexName, startTimestamp, endTimestamp, store) {
	var parametersElasticData = [];
	store.getters.getParameters.forEach((parameter) => {
		const promise = lastData(indexName, query_HistoricDataByParameter(stationCode, parameter.elasticacronym, startTimestamp, endTimestamp));
		promise.then((elasticData) => {
			parametersElasticData[parameter.elasticacronym] = elasticData;
			store.commit('setHistoricElasticData', {
				index: parameter.elasticacronym,
				data: elasticData
			});
		});
	});
}
// NOTE: UNUSED
export async function obtainParameterHistoricData(stationCode, indexName, startTimestamp, endTimestamp, elasticacronym, store) {
	const promise = lastData(indexName, query_HistoricDataByParameter(stationCode, elasticacronym, startTimestamp, endTimestamp));
	promise.then((elasticData) => {
		if (elasticData.message.length > 0) {
			store.commit('setHistoricElasticData', {
				index: elasticacronym,
				data: elasticData
			});
			return elasticData;
		} else {
			store.commit('setHistoricElasticData', {
				index: elasticacronym,
				data: null
			});
			return null;
		}
	});
}

export function filterByParameter(pollutant) {
	const filterPollutant = {
		groups: [],
		groupOp: 'and',
		rules: [{ field: 'acronym', op: 'eq', data: pollutant }]
	};
	const body = {
		model: 'pmparameter',
		filter: filterPollutant
	};

	return body;
}

export function ChartOptions(tabConstant) {
	const filterChartOptions = {
		groups: [],
		groupOp: 'and',
		rules: [
			{ field: 'visualizationtype', op: 'cn', data: tabConstant },
			{ field: 'disabled', op: 'eq', data: 0 }
		]
	};

	const body = {
		model: 'pmvisualizations',
		filter: filterChartOptions
	};
	return body;
}

export function obtainIndexName(deviceObject) {
	let indexName = null;
	if (deviceObject.elasticacronym && deviceObject.organizationacronym) {
		//indexName = constants.ELASTIC_INDEX_PREFIX + '_' + deviceObject.organizationacronym.toLowerCase() + '_' + deviceObject.elasticacronym;
		indexName = constants.ELASTIC_INDEX_PREFIX + '-' + deviceObject.organizationacronym.toLowerCase() + '-' + deviceObject.elasticacronym;
	}
	return indexName;
}

export function filterString(Arrdates) {
	let uniqueDates = [];
	Arrdates.forEach((date) => {
		if (!uniqueDates.includes(date)) {
			uniqueDates.push(date);
		}
	});
	return uniqueDates;
}

export function fillValues(arrDates, arrValues, uniqueDates) {
	let uniqueValues = [];
	for (let i = 0; i < uniqueDates.length; i++) {
		if (!arrDates.includes(uniqueDates[i])) {
			uniqueValues.push(null);
		} else {
			for (let j = 0; j < arrDates.length; j++) {
				if (arrDates[j] == uniqueDates[i]) {
					uniqueValues.push(arrValues[j]);
				}
			}
		}
	}

	return uniqueValues;
}

export function averageForNullValues(arrObject) {
	for (let r = 0; r < arrObject.length; r++) {
		if (arrObject[r]['value'] === null && r !== arrObject.length) {
			var previousValue = previousValueNotNull(r, arrObject);
			var forwardValue = forwardValueNotNull(r, arrObject);
			let newValue = (previousValue + forwardValue) / 2;
			arrObject[r]['value'] = newValue;
		}

		if (arrObject[r]['value'] === null && r === arrObject.length) {
			var preValue = previousValueNotNull(r, arrObject);
			arrObject[r]['value'] = preValue;
		}
	}
}

export function previousValueNotNull(index, arrObject) {
	var previousValue = null;
	while (index-- && arrObject[index]['value'] !== null) {
		previousValue = arrObject[index]['value'];
		break;
	}
	return previousValue;
}

export function forwardValueNotNull(index, arrObject) {
	var forwardValue = null;
	var valueChanged = false;
	while (index++ && index < arrObject.length) {
		if (arrObject[index]['value'] !== null) {
			forwardValue = arrObject[index]['value'];
			valueChanged = true;
			break;
		}
	}
	if (!valueChanged) {
		forwardValue = previousValueNotNull(index, arrObject);
	}
	return forwardValue;
}

export function toggleActiveClass(elementId, option) {
	if (document.getElementById(elementId)) {
		let element = document.getElementById(elementId);
		option ? element.classList.add('active') : element.classList.remove('active');
	}
}

export async function obtainPanelPredictionsDateRange(elasticAcronym, datasourcetypeId, indexName, store) {
	var parametersFirstDateData = [];
	var parametersLastDateData = [];
	var parametersAcronymsFirst = [];
	var parametersAcronymsLast = [];
	var promisesElasticFirst = [];
	var promisesElasticLast = [];
	var datasourceParameters = store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == datasourcetypeId);

	datasourceParameters.forEach((parameter) => {
		// First Dates
		promisesElasticFirst.push(lastData(indexName, waterportsquery_FirstDataByWaterPollutant(parameter.elasticacronym)));
		parametersAcronymsFirst.push(parameter.elasticacronym);
		// Last Dates
		promisesElasticLast.push(lastData(indexName, waterportsquery_LastDataByWaterPollutant(parameter.elasticacronym)));
		parametersAcronymsLast.push(parameter.elasticacronym);
	});

	Promise.all(promisesElasticFirst).then((elasticData) => {
		for (let i = 0; i < elasticData.length; i++) {
			if (typeof elasticData[i].message[0] !== 'undefined') {
				let messageData = elasticData[i].message[0]._source;
				parametersFirstDateData[parametersAcronymsFirst[i]] = new Date(messageData['date_timestamp']).toISOString();
			}
		}
		//parametersElasticData[parameter.elasticacronym] = elasticData;
		store.commit('setPredictionFirstDates', parametersFirstDateData);
	});

	Promise.all(promisesElasticLast).then((elasticData) => {
		for (let i = 0; i < elasticData.length; i++) {
			if (typeof elasticData[i].message[0] !== 'undefined') {
				let messageData = elasticData[i].message[0]._source;
				parametersLastDateData[parametersAcronymsLast[i]] = new Date(messageData['date_timestamp']).toISOString();
			}
		}

		//parametersElasticData[parameter.elasticacronym] = elasticData;
		store.commit('setPredictionLastDates', parametersLastDateData);
	});
}

export async function setRules(store) {
	let userProperties = store.getters.getUserProperties;
	let filterUser = {};
	if (!isSuperAdmin) {
		filterUser = {
			groups: [],
			groupOp: 'and',
			rules: [{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid }]
		};
	}
	let body = {
		model: 'pmrules',
		rows: 999,
		filter: filterUser
	};

	let { data } = await search(body);
	store.commit('setRules', data.data);
	return data.data;
}

export async function setTimeunits(store) {
	var userProperties = store.getters.getUserProperties;
	let filterUser = {};
	if (!isSuperAdmin) {
		filterUser = {
			groups: [],
			groupOp: 'and',
			rules: [{ field: 'pmorganizationid', op: 'eq', data: userProperties.organizationid }]
		};
	}
	let body = {
		model: 'vluppmtimeunit',
		rows: 99,
		filter: filterUser
	};

	let { data } = await search(body);
	store.commit('setTimeunits', data.data);
	return data.data;
}

export async function setErrorCodes(store) {
	let filter = {};
	let body = {
		model: 'pmerrorcodes',
		rows: 1000,
		filter: filter
	};

	let { data } = await search(body);
	store.commit('setErrorCodes', data.data);
	return data.data;
}

export function formatRuleModel(model, allTimeunits) {
	model.valid = true;
	model.isaverage = 0;

	if (model.elasticindex) {
		model.elasticIndexNotifications = model.elasticindex + '_' + constants.ELASTIC_NOTIFICATIONS_SUFFIX;
	} else {
		model.valid = false;
	}
	// Apply to all
	if (model.applytoall == 1) {
		model.datasourceinstances = [];
	} else {
		if (model.datasourceinstances.length > 0) {
			model.devicesCodes = model.datasourceinstances.map((obj) => obj.code);
		} else {
			model.devicesCodes = [''];
		}
	}
	// Condition Elements
	if (model.filter) {
		let filter = JSON.parse(model.filter);
		model.numberOfRules = filter.rules.length;
		filter.rules.forEach((rule) => {
			rule.field ? (model.parameterelasticacronym = rule.field) : (model.valid = false);
			rule.op ? (model.conditionComparatorExpression = rule.op) : (model.valid = false);
			if (rule.data) {
				if (rule.op == 'bt' || rule.op == 'nbt') {
					model.conditionValue = rule.data;
				} else {
					model.conditionValue = parseFloat(rule.data);
				}
			} else if (rule.op == 'nu' || rule.op == 'nn') {
				model.conditionValue = null;
			} else {
				model.valid = false;
			}
		});
	} else {
		model.valid = false;
	}

	// Condition Operator
	if (model.conditionComparatorExpression.split('_').length == 2) {
		model.isaverage = 1;
		switch (model.conditionComparatorExpression.split('_')[1]) {
			case 'gt':
				model.conditionComparatorSymbol = '>';
				break;
			case 'lt':
				model.conditionComparatorSymbol = '<';
				break;
			case 'gte':
				model.conditionComparatorSymbol = '>=';
				break;
			case 'lte':
				model.conditionComparatorSymbol = '<=';
				break;
			case 'eq':
				model.conditionComparatorSymbol = '=';
				break;
			case 'nn':
			case 'nu':
				model.conditionComparatorSymbol = '=';
				break;
			case 'bt':
			case 'nbt':
				model.conditionComparatorSymbol = '>';
				break;
			default:
				model.conditionComparatorSymbol = '=';
				model.valid = false;
				break;
		}
	} else {
		switch (model.conditionComparatorExpression) {
			case 'gt':
				model.conditionComparatorSymbol = '>';
				break;
			case 'lt':
				model.conditionComparatorSymbol = '<';
				break;
			case 'gte':
				model.conditionComparatorSymbol = '>=';
				break;
			case 'lte':
				model.conditionComparatorSymbol = '<=';
				break;
			case 'eq':
				model.conditionComparatorSymbol = '=';
				break;
			case 'nn':
			case 'nu':
				model.conditionComparatorSymbol = '=';
				break;
			case 'bt':
			case 'nbt':
				model.conditionComparatorSymbol = '>';
				break;
			default:
				model.conditionComparatorSymbol = '=';
				model.valid = false;
				break;
		}
	}

	// Timeunit Frequency
	if (model.frequency && allTimeunits.length > 0) {
		const frequencyTimeunit = allTimeunits.find((timeunit) => timeunit.pmtimeunitid == model.pmfrequencytimeunitid);
		const windowTimeunit = allTimeunits.find((timeunit) => timeunit.pmtimeunitid == model.pmwindowtimeunitid);
		if (windowTimeunit && frequencyTimeunit) {
			model.timeunitacronym = windowTimeunit.acronym;
			model.frequencyinterval = model.frequency + frequencyTimeunit.acronym;
		} else {
			model.valid = false;
		}
	} else {
		model.valid = false;
	}

	return model;
}

export function formatRuleModelMulti(model, allTimeunits) {
	model.valid = true;
	model.multiFilter = true;
	model.isaverage = 0;

	if (model.elasticindex) {
		model.elasticIndexNotifications = model.elasticindex + '_' + constants.ELASTIC_NOTIFICATIONS_SUFFIX;
	} else {
		model.valid = false;
	}
	// Apply to all
	if (model.applytoall == 1) {
		model.datasourceinstances = [];
	} else {
		if (model.datasourceinstances.length > 0) {
			model.devicesCodes = model.datasourceinstances.map((obj) => obj.code);
		} else {
			model.devicesCodes = [''];
		}
	}

	// Condition Elements MULTIPLE
	if (model.filter) {
		let filter = JSON.parse(model.filter);
		model.numberOfRules = filter.rules.length;
		model.groupOp = model.filter.groupOp ? model.filter.groupOp : 'and';
		model.parameterelasticacronym = [];
		model.conditionComparatorExpression = [];
		model.conditionValue = [];
		model.conditionComparatorSymbol = [];

		filter.rules.forEach((rule, index) => {
			rule.field ? (model.parameterelasticacronym[index] = rule.field) : (model.valid = false);
			rule.op ? (model.conditionComparatorExpression[index] = rule.op) : (model.valid = false);
			if (rule.data) {
				if (rule.op == 'bt' || rule.op == 'nbt') {
					model.conditionValue[index] = rule.data;
				} else {
					model.conditionValue[index] = parseFloat(rule.data);
				}
			} else if (rule.op == 'nu' || rule.op == 'nn') {
				model.conditionValue[index] = null;
			} else {
				model.valid = false;
			}

			// Condition Operator
			switch (model.conditionComparatorExpression[index]) {
				case 'gt':
					model.conditionComparatorSymbol[index] = '>';
					break;
				case 'lt':
					model.conditionComparatorSymbol[index] = '<';
					break;
				case 'gte':
					model.conditionComparatorSymbol[index] = '>=';
					break;
				case 'lte':
					model.conditionComparatorSymbol[index] = '<=';
					break;
				case 'eq':
					model.conditionComparatorSymbol[index] = '=';
					break;
				case 'ne':
					model.conditionComparatorSymbol[index] = '!=';
					break;
				case 'nn':
				case 'nu':
					model.conditionComparatorSymbol[index] = '=';
					break;
				case 'bt':
				case 'nbt':
					model.conditionComparatorSymbol[index] = '>';
					break;
				default:
					model.conditionComparatorSymbol[index] = '=';
					model.valid = false;
					break;
			}
		});
	} else {
		model.valid = false;
	}

	// Timeunit Frequency
	if (model.frequency && allTimeunits.length > 0) {
		const frequencyTimeunit = allTimeunits.find((timeunit) => timeunit.pmtimeunitid == model.pmfrequencytimeunitid);
		const windowTimeunit = allTimeunits.find((timeunit) => timeunit.pmtimeunitid == model.pmwindowtimeunitid);
		if (windowTimeunit && frequencyTimeunit) {
			model.timeunitacronym = windowTimeunit.acronym;
			model.frequencyinterval = model.frequency + frequencyTimeunit.acronym;
		} else {
			model.valid = false;
		}
	} else {
		model.valid = false;
	}

	return model;
}

export function formatRuleModelQuery(model, allTimeunits) {
	model.valid = true;
	if (model.elasticindex) {
		model.elasticIndexNotifications = model.elasticindex + '_' + constants.ELASTIC_NOTIFICATIONS_SUFFIX;
	} else {
		model.valid = false;
	}
	// Apply to all
	if (model.applytoall == -1 || model.applytoall == 1) {
		model.datasourceinstances = [];
	} else {
		if (model.datasourceinstances.length > 0) {
			model.devicesCodes = model.datasourceinstances.map((obj) => obj.code);
		} else {
			model.devicesCodes = [''];
		}
	}
	// Condition Elements
	if (!model.elasticquery) {
		model.valid = false;
	}

	// Condition Operator
	model.conditionComparatorSymbol = '>';

	// Timeunit Frequency
	if (model.frequency && allTimeunits.length > 0) {
		const frequencyTimeunit = allTimeunits.find((timeunit) => timeunit.pmtimeunitid == model.pmfrequencytimeunitid);
		const windowTimeunit = allTimeunits.find((timeunit) => timeunit.pmtimeunitid == model.pmwindowtimeunitid);
		if (windowTimeunit && frequencyTimeunit) {
			model.timeunitacronym = windowTimeunit.acronym;
			model.frequencyinterval = model.frequency + frequencyTimeunit.acronym;
		} else {
			model.valid = false;
		}
	} else {
		model.valid = false;
	}

	return model;
}

export function adjustNotificationsHeight(data) {
	setTimeout(() => {
		let constantHeight = 120;
		//let viewportFeatures = state.data[NOTIFICATIONS_LAYER].viewportFeatures;
		if (data[NOTIFICATIONS_LAYER].viewportFeatures.length > constants.NOTIFICATION_PANEL_THRESHOLD) constantHeight += 30;

		if (document.getElementById('panel-notifications-detail')) {
			let configHeight = document.getElementById('notifications-config').clientHeight + constantHeight;
			document.documentElement.style.setProperty('--notification-panel-diff', configHeight + 'px');
		}
	}, 200);
}

export function calculateAQI(resultAQI) {
	let max = { value: 0, parameter: null, rawValue: 0 },
		maxPM25 = 0,
		maxPM10 = 0,
		maxO3 = 0,
		maxNO2 = 0,
		maxSO2 = 0;
	// PM2.5
	if (resultAQI['max_pm25'].value > 0 && resultAQI['max_pm25'].value < 10) {
		maxPM25 = 1;
	} else if (resultAQI['max_pm25'].value >= 10 && resultAQI['max_pm25'].value < 20) {
		maxPM25 = 2;
	} else if (resultAQI['max_pm25'].value >= 20 && resultAQI['max_pm25'].value < 25) {
		maxPM25 = 3;
	} else if (resultAQI['max_pm25'].value >= 25 && resultAQI['max_pm25'].value < 50) {
		maxPM25 = 4;
	} else if (resultAQI['max_pm25'].value >= 50 && resultAQI['max_pm25'].value < 75) {
		maxPM25 = 5;
	} else if (resultAQI['max_pm25'].value >= 75) {
		maxPM25 = 6;
	} else {
		console.error('ERROR pm25: out of range', resultAQI['max_pm25']);
	}
	if (maxPM25 < 6) {
		// PM10
		if (resultAQI['max_pm10'].value > 0 && resultAQI['max_pm10'].value < 20) {
			maxPM10 = 1;
		} else if (resultAQI['max_pm10'].value >= 25 && resultAQI['max_pm10'].value < 40) {
			maxPM10 = 2;
		} else if (resultAQI['max_pm10'].value >= 40 && resultAQI['max_pm10'].value < 50) {
			maxPM10 = 3;
		} else if (resultAQI['max_pm10'].value >= 50 && resultAQI['max_pm10'].value < 100) {
			maxPM10 = 4;
		} else if (resultAQI['max_pm10'].value >= 100 && resultAQI['max_pm10'].value < 150) {
			maxPM10 = 5;
		} else if (resultAQI['max_pm10'].value >= 150) {
			maxPM10 = 6;
		} else {
			console.error('ERROR pm10: out of range', resultAQI['max_pm10']);
		}

		if (maxPM10 < 6) {
			// O3
			if (resultAQI['max_o3'].value > 0 && resultAQI['max_o3'].value < 50) {
				maxO3 = 1;
			} else if (resultAQI['max_o3'].value >= 50 && resultAQI['max_o3'].value < 100) {
				maxO3 = 2;
			} else if (resultAQI['max_o3'].value >= 100 && resultAQI['max_o3'].value < 130) {
				maxO3 = 3;
			} else if (resultAQI['max_o3'].value >= 130 && resultAQI['max_o3'].value < 240) {
				maxO3 = 4;
			} else if (resultAQI['max_o3'].value >= 240 && resultAQI['max_o3'].value < 380) {
				maxO3 = 5;
			} else if (resultAQI['max_o3'].value >= 380) {
				maxO3 = 6;
			} else {
				console.error('ERROR o3: out of range', resultAQI['max_o3']);
			}
			if (maxO3 < 6) {
				// NO2
				if (resultAQI['max_no2'].value > 0 && resultAQI['max_no2'].value < 40) {
					maxNO2 = 1;
				} else if (resultAQI['max_no2'].value >= 40 && resultAQI['max_no2'].value < 90) {
					maxNO2 = 2;
				} else if (resultAQI['max_no2'].value >= 90 && resultAQI['max_no2'].value < 120) {
					maxNO2 = 3;
				} else if (resultAQI['max_no2'].value >= 120 && resultAQI['max_no2'].value < 230) {
					maxNO2 = 4;
				} else if (resultAQI['max_no2'].value >= 230 && resultAQI['max_no2'].value < 340) {
					maxNO2 = 5;
				} else if (resultAQI['max_no2'].value >= 340) {
					maxNO2 = 6;
				} else {
					console.error('ERROR no2: out of range', resultAQI['max_no2']);
				}
				if (maxNO2 < 6) {
					// SO2
					if (resultAQI['max_so2'].value > 0 && resultAQI['max_so2'].value < 100) {
						maxSO2 = 1;
					} else if (resultAQI['max_so2'].value >= 100 && resultAQI['max_so2'].value < 200) {
						maxSO2 = 2;
					} else if (resultAQI['max_so2'].value >= 200 && resultAQI['max_so2'].value < 350) {
						maxSO2 = 3;
					} else if (resultAQI['max_so2'].value >= 350 && resultAQI['max_so2'].value < 500) {
						maxSO2 = 4;
					} else if (resultAQI['max_so2'].value >= 500 && resultAQI['max_so2'].value < 750) {
						maxSO2 = 5;
					} else if (resultAQI['max_so2'].value >= 750) {
						maxSO2 = 6;
					} else {
						console.error('ERROR so2: out of range', resultAQI['max_so2']);
					}
					if (maxSO2 == 6) {
						max.value = maxSO2;
						max.parameter = 'so2';
						max.rawValue = resultAQI['max_so2'].value;
					}
				} else max = { value: maxNO2, parameter: 'no2', rawValue: resultAQI['max_no2'].value };
			} else max = { value: maxO3, parameter: 'o3', rawValue: resultAQI['max_o3'].value };
		} else max = { value: maxPM10, parameter: 'pm10', rawValue: resultAQI['max_o3'].value };
	} else max = { value: maxPM25, parameter: 'pm25', rawValue: resultAQI['max_o3'].value };

	if (max.value == 0) {
		max = maxPM25;
		if (maxPM10 > max) max = { value: maxPM10, parameter: 'pm10', rawValue: resultAQI['max_pm10'].value };
		if (maxO3 > max) max = { value: maxO3, parameter: 'o3', rawValue: resultAQI['max_o3'].value };
		if (maxNO2 > max) max = { value: maxNO2, parameter: 'no2', rawValue: resultAQI['max_no2'].value };
		if (maxSO2 > max) max = { value: maxSO2, parameter: 'so2', rawValue: resultAQI['max_so2'].value };
	}
	return max;
}
export function calculateCAQI(resultCAQI) {
	let max = { value: 0, parameter: null, rawValue: 0 },
		maxPM10 = 0,
		maxO3 = 0,
		maxNO2 = 0;
	// PM10
	if (resultCAQI['max_pm10'].value > 0 && resultCAQI['max_pm10'].value < 25) {
		maxPM10 = 1;
	} else if (resultCAQI['max_pm10'].value >= 25 && resultCAQI['max_pm10'].value < 50) {
		maxPM10 = 2;
	} else if (resultCAQI['max_pm10'].value >= 50 && resultCAQI['max_pm10'].value < 90) {
		maxPM10 = 3;
	} else if (resultCAQI['max_pm10'].value >= 90 && resultCAQI['max_pm10'].value < 180) {
		maxPM10 = 4;
	} else if (resultCAQI['max_pm10'].value >= 180 && resultCAQI['max_pm10'].value < 360) {
		maxPM10 = 5;
	} else if (resultCAQI['max_pm10'].value >= 360) {
		maxPM10 = 6;
	} else {
		console.error('ERROR pm10: out of range', resultCAQI['max_pm10']);
	}

	if (maxPM10 < 6) {
		// O3
		if (resultCAQI['max_o3'].value > 0 && resultCAQI['max_o3'].value < 60) {
			maxO3 = 1;
		} else if (resultCAQI['max_o3'].value >= 60 && resultCAQI['max_o3'].value < 120) {
			maxO3 = 2;
		} else if (resultCAQI['max_o3'].value >= 120 && resultCAQI['max_o3'].value < 180) {
			maxO3 = 3;
		} else if (resultCAQI['max_o3'].value >= 180 && resultCAQI['max_o3'].value < 240) {
			maxO3 = 4;
		} else if (resultCAQI['max_o3'].value >= 240 && resultCAQI['max_o3'].value < 300) {
			maxO3 = 5;
		} else if (resultCAQI['max_o3'].value >= 300) {
			maxO3 = 6;
		} else {
			console.error('ERROR o3: out of range', resultCAQI['max_o3']);
		}
		if (maxO3 < 6) {
			// NO2
			if (resultCAQI['max_no2'].value > 0 && resultCAQI['max_no2'].value < 50) {
				maxNO2 = 1;
			} else if (resultCAQI['max_no2'].value >= 50 && resultCAQI['max_no2'].value < 100) {
				maxNO2 = 2;
			} else if (resultCAQI['max_no2'].value >= 100 && resultCAQI['max_no2'].value < 200) {
				maxNO2 = 3;
			} else if (resultCAQI['max_no2'].value >= 200 && resultCAQI['max_no2'].value < 400) {
				maxNO2 = 4;
			} else if (resultCAQI['max_no2'].value >= 400 && resultCAQI['max_no2'].value < 600) {
				maxNO2 = 5;
			} else if (resultCAQI['max_no2'].value >= 600) {
				maxNO2 = 6;
			} else {
				console.error('ERROR no2: out of range', resultCAQI['max_no2']);
			}
			if (maxNO2 == 6) {
				max.value = maxNO2;
				max.parameter = 'no2';
				max.rawValue = resultCAQI['max_no2'].value;
			}
		} else {
			max.value = maxO3;
			max.parameter = 'o3';
			max.rawValue = resultCAQI['max_o3'].value;
		}
	} else {
		max.value = maxPM10;
		max.parameter = 'pm10';
		max.rawValue = resultCAQI['max_pm10'].value;
	}

	if (max.value == 0) {
		max = { value: maxPM10, parameter: 'pm10', rawValue: resultCAQI['max_pm10'].value };
		if (maxO3 > max.value) max = { value: maxO3, parameter: 'o3', rawValue: resultCAQI['max_o3'].value };
		if (maxNO2 > max.value) max = { value: maxNO2, parameter: 'no2', rawValue: resultCAQI['max_no2'].value };
	}
	return max;
}

export function getColorAQI(aqi) {
	let color = '#6f7480';
	switch (aqi) {
		case 1:
			color = '#00E400';
			break;
		case 2:
			color = '#FFFF00';
			break;
		case 3:
			color = '#FF7E00';
			break;
		case 4:
			color = '#FF0000';
			break;
		case 5:
			color = '#99004C';

			break;
		case 6:
			color = '#7E0023';
			break;
		default:
			break;
	}
	return color;
}

export function getStatusProperties(status, value) {
	let statusProperties = null;
	try {
		const statusArray = JSON.parse(status.status);
		const colors = JSON.parse(status.color);
		const names = JSON.parse(status.name);
		statusArray.forEach((status, i) => {
			if (evalLowerLimit(value, status.min.data, status.min.op) && evalUpperLimit(value, status.max.data, status.max.op)) {
				statusProperties = { name: names[i], color: colors[i], status: status };
			}
		});
	} catch (error) {
		console.error('Error while parsing status data: ', error);
		statusProperties = null;
	}

	return statusProperties;
}
const evalLowerLimit = (value, min, op) => {
	let res = false;
	if (min) {
		switch (op) {
			case 'gt':
				if (value > min) res = true;
				break;
			case 'gte':
				if (value >= min) res = true;
				break;
		}
	} else res = true;

	return res;
};
const evalUpperLimit = (value, max, op) => {
	let res = false;
	if (max) {
		switch (op) {
			case 'lt':
				if (value < max) res = true;
				break;
			case 'lte':
				if (value <= max) res = true;
				break;
		}
	} else res = true;

	return res;
};

Date.prototype.addHours = function (h) {
	this.setTime(this.getTime() + h * 60 * 60 * 1000);
	return this;
};

export async function insert(body, model) {
	const controller = model + '/insert';
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		body,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);

	return data;
}

export function sortKey(item) {
	const [text, number] = item.split('-');
	return [text, parseInt(number, 10)];
}
